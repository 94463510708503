.page {
  z-index: 0;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 10;
  background: white;
  border-bottom: 1px solid #2860a8;
  justify-items: center;
}

.content {
  justify-content: center;
  padding-bottom: 60px;
  flex-wrap: wrap;
  min-height: calc(100vh - 150px);
  padding: 10px;
}
