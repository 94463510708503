.message_box {
  width: 100%;
  max-width: 600px;
  margin: 1em 0;
  padding: 1em;
}
.message_box.error {
  background-color: #de3a1d;
}
.message_box.success {
  background-color: #8aba1f;
}
.message_box.busy {
  background-color: #686868;
}
